
.gen-container .bv-w{
    background-image: url('https://res.cloudinary.com/dozkyuutd/image/upload/v1709367554/banner_g5l5lr.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .section{
   position: relative;
    width: 100%;
  }
  .sec-1{
    height: 100vh;
  }
  .main-heading{
  font-family: 'Varela Round', sans-serif;
  line-height: 1.2;
  }
  .sub-heading{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
      font-size: 17px;
  }
  .svg-btn {
    rotate: -90deg;
    margin-left: 4px;
    margin-top: -3px;
  
  }
  .xd-outer{
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  
  }
  .xd-shape1{
    background-image: url(https://thapar-dog-school.odoo.com/web_editor/shape/web_editor/Wavy/22.svg?c3=o-color-3&flip=x);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: absolute;
    background-position: 50% 100%;
    /* z-index: 9; */
    /* float: left; */
    background-size: 100% auto;
  }
  .vb{
    position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute !important;
      display: block;
      overflow: hidden;
      background-repeat: no-repeat;
      pointer-events: none;
  }
  
  .xd-shape4{
    background-image: url(https://thapar-dog-school.odoo.com/web_editor/shape/web_editor/Floats/13.svg?c1=o-color-1&c2=o-color-2&c5=o-color-5&flip=x);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat no-repeat;
  }
  .sec-4{
    background-image: url(https://thapar-dog-school.odoo.com/web_editor/shape/web_editor/Floats/14.svg?c1=%230AA2C0&c2=%23dbb4ac&c3=%23f0f4f5&c5=%23142a2e);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat repeat;
  }
  .s_hr{
    padding-top: 8px;
      padding-bottom: 32px;
      width: 222px;
  }
  .s_hr hr{
    border-bottom: 4px dotted #dbb4ac;
    border-top-width: 0;
  }
  .s_hr p{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 16px;
  }
  .sec-2 h2{
    color: #142a2e;
    font-family: 'Varela Round';
    font-size: 40px;
  }
  .xd-shape2{
    background-image: url(https://thapar-dog-school.odoo.com/web_editor/shape/web_editor/Origins/14_001.svg?c3=o-color-3&c4=rgba%280%2C%200%2C%200%2C%200%29&flip=y);
      background-position: 50% 0%;
      background-size: 100% auto;
      background-repeat: no-repeat no-repeat;
  }
  .card h3,.happy-page h3,.happy-page a{
    font-family: 'Varela Round';
  }
  .card p,.sm-cards a{
    font-family: 'Montserrat', sans-serif;
  }
  .card hr {
    border-bottom: 5px dotted #dbb4ac;
    width: 80px;
    border-top-width: 0;
    margin: 0 auto 32px;
    padding-top: 8px;
  
  }
  .rex{
    border-bottom: 5px dotted #0aa2c0!important;
  }
  .sm-cards{
    border: 4px dotted #dbb4ac;
    border-radius: 16px !important;
    margin: 0 auto;
      padding: 0 15px 24px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: column;
  }
  .sm-cards svg{
    height: 130px;
    width: 130px;
  }
  .happy-page {
    margin: 0 12%;
  }
  .happy-page p{
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  }
  
  @media (min-width: 1400px){
    .container{
      max-width: 1320px;
      margin: 0 auto;
    }
  }
  .social-links a {
    width: 48px;
    height: 48px;
    display: flex;
    background: #fff;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
}
.social-links a svg{
  width: 25px;
  height: 25px;
}
.company-info,.copyright{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 300;
}
.bx-wrapper{
  position: relative;
}
.bx-wrapper::after {
  content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 50%;
    right: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.369) 19%, rgba(0, 0, 0, 0.2705) 34%, rgba(0, 0, 0, 0.191) 47%, rgba(0, 0, 0, 0.139) 56.5%, rgba(0, 0, 0, 0.097) 65%, rgba(0, 0, 0, 0.063) 73%, rgba(0, 0, 0, 0.0375) 80.2%, rgba(0, 0, 0, 0.021) 86.1%, rgba(0, 0, 0, 0.0105) 91%, rgba(0, 0, 0, 0.004) 95.2%, rgba(0, 0, 0, 0.001) 98.2%, rgba(0, 0, 0, 0) 100%);
}
.privacy-container{
  max-width: 1400px;
  padding-inline: 20px;
  margin-inline: auto;
 font-family: "Mulish", sans-serif;
 color: #313131;
 padding-block: 70px;
}

.privacy-container h1{
 font-size: 48px; 
 margin-bottom: 0.5rem;
 color: #dbb4ac;
 font-weight: 800;
 line-height: 1.4em;
 text-align: left;
 margin-block-end: 40px;
}

.privacy-container h2{
  font-size: 28px;
  line-height: 120%;
  font-weight: 800;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.privacy-container p{
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 17px;
  line-height: 1.5;
}

.privacy-container a{
  text-decoration: underline;
  color: #1bbd7c;
}
.privacy-container ul li{
  font-size: 17px;
  text-decoration: dotted;
  list-style:disc;
}
.privacy-container ul{
  padding-left: 30px;
}
.hj-o{
  position: absolute; /* Positions footer at the bottom */
  bottom: 0; /* Anchors footer to bottom of viewport */
  left: 0; /* Aligns footer to left edge */
  width: 100%;
}
.shadow-all{
  box-shadow: 1px 1px 12px #0000001a;
}
.review-sec .swiper{
  padding: 0 50px;
}
input.add-promo::placeholder{
  color:rgb(102 102 102 / 40%) !important
}
.add-cart{
  background: #dbb4ac;
  color: #313131;
}
section .swipe-up {
  position: absolute;
  bottom: 189px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  padding-top: 80px;
}

.sec-1  .swipe-up:hover {
  opacity: .5;
}

.sec-1 .swipe-up  span {
position: absolute;
top: 0;
left: 50%;
width: 24px;
height: 24px;
margin-left: -12px;
border-left: 3px solid #fff;
border-bottom: 3px solid #fff;
border-radius: 3px;
-webkit-transform: rotate(135deg);
transform: rotate(135deg);
-webkit-animation: animationscroll 2s infinite;
animation: animationscroll 2s infinite;
opacity: 0;
box-sizing: border-box;
}
.sec-1  .swipe-up span:nth-of-type(1) {
-webkit-animation-delay: 0.3s;
animation-delay: 0.3s;
}
.sec-1  .swipe-up  span:nth-of-type(2) {
top: 16px;
-webkit-animation-delay: .15s;
animation-delay: .15s;
}
.sec-1  .swipe-up  span:nth-of-type(3) {
top: 32px;
-webkit-animation-delay: 0s;
animation-delay: 0s;
}
@-webkit-keyframes animationscroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animationscroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media (max-width:480px){
  section .swipe-up {
    bottom: 102px!important;
  }
  .user-billing{
    height: 100vh;
    overflow-y: scroll;
  }
  .gen-container .bv-w{
    background-image: url('https://res.cloudinary.com/dozkyuutd/image/upload/v1710591336/testFolder2/wpflzlslvsw4kd5m4cek.jpg');
  }
  .lg-page input.form-style{
    font-size: 14px!important;
  }
  .s-mt-4xl{margin-top: 70px!important;}
  .s-mt-0{
    margin-top: 0!important;
  }
  .s-mb-0{
    margin-bottom: 0!important;
  }
  .s-m-0{
    margin: 0!important;
  }
  .smb-3 {
    margin-bottom: 3.5rem!important;
  }
  .about-sec{text-align: center;}
  .about-sec header,.about-sec h1,.learning-grid .lea {
    font-size: 28px;
  }
  .learning-grid {
    align-items: center;
  }
  .catalog-section {
   padding-left: 0!important;
   padding-right: 0!important;
   padding-bottom: 0!important;
  }
  img.w-full{
    width: auto!important;
  }
  .section{
    padding: 64px 0!important;
  }
  .container{
    padding: 0 15px;
  }
  .w-1\/2{
    width: 100%;
  }
 .sec-1 .w-1\/2 {
   margin-top: -58%;
 }
 .sec-1 a div {
  font-size: 16px!important;
 }
 .main-heading{
  font-size: 38px;
 }
p {
  font-size:15px!important
 }
 .xd-shape1,.xd-shape4,.xd-shape2{
  display: none;
}
.sec-2 .container > div{
  flex-direction: column;
}
.sec-2 .sm\:ml-0{
  margin-left: 0;
  margin-block-start: 20px;
}
 h2{
  font-size: 28px!important;
}
h3{
  font-size: 23px!important;
  line-height: 1.4!important;
}
.sec-3 .grid{
grid-template-columns: auto;
padding-bottom: 0!important;
}
.card h3{margin: 0;}
.sm-cards{
  width: auto;
}
.sec-4{
  background: none;
}
.happy-page{
  margin: 0;
}
.happy-page p{
  font-weight: 300;
}
.social-links {
  padding-top: 32px;
}
.company-info ul{
  flex-direction: column;
}
}